import {
    EN_PRICES,
    EN_RESOURCES,
    EN_SERVICE,
    ENGLISH_MAIN_ROUTE,
    EN_US,
    ES_SERVICE,
    ES_PRICES,
    ES_RESOURCES,
    ES_US,
    ES_LOGIN_OWNERS, EN_LOGIN_OWNERS
} from "../../routes/constantsRoutes";

export interface PathRouteInterface {
    id: string,
    name: string,
    path_es: string,
    path_en: string,
    translateName: string,
    target: string,
}

export const navBarPagesValues: Array<PathRouteInterface> = [
    {
        'id': 'service',
        'name': 'web.navBar.service',
        'path_es': ES_SERVICE,
        'path_en': ENGLISH_MAIN_ROUTE + EN_SERVICE,
        'translateName': 'service',
        'target': '_self'
    },
    {
        'id': 'prices',
        'name': 'web.navBar.prices',
        'path_es': ES_PRICES,
        'path_en': ENGLISH_MAIN_ROUTE + EN_PRICES,
        'translateName': 'prices',
        'target': '_self'
    },
    {
        'id': 'resources',
        'name': 'web.navBar.resources',
        'path_es': ES_RESOURCES,
        'path_en': ENGLISH_MAIN_ROUTE + EN_RESOURCES,
        'translateName': 'resources',
        'target': '_self'
    },
    {
        'id': 'our',
        'name': 'web.navBar.our',
        'path_es': ES_US,
        'path_en': ENGLISH_MAIN_ROUTE + EN_US,
        'translateName': 'our',
        'target': '_self'
    },
    {
        'id': 'property',
        'name': 'web.navBar.property',
        'path_es': ES_LOGIN_OWNERS,
        'path_en': ENGLISH_MAIN_ROUTE + EN_LOGIN_OWNERS,
        'translateName': 'property',
        'target': '_blank'
    }
];

export const navBarEstimationValue: PathRouteInterface =
    {
        'id': 'estimate',
        'name': 'web.navBar.estimate',
        'path_es': ES_PRICES,
        'path_en': ENGLISH_MAIN_ROUTE + EN_PRICES,
        'translateName': 'estimate',
        'target': '_self'
    };

