import { Grid, Typography } from "@mui/material";
import { createMarkup } from "../utils/textUtilsResources";

const LegalENTextContent = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} mt={5} mb={5}>
                <Grid container justifyContent={'center'}>
                    <Typography variant={'h1'} fontSize={'2em'} fontWeight={'bold'}
                                dangerouslySetInnerHTML={createMarkup('Winahost Privacy Policy')}/>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Effective Date: </strong>08/29/2024'
                                    )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('Winahost ("we", "our", or "us") operates the website <a href="https://winahost.com">https://winahost.com</a> (hereinafter referred to as the "Service").')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from <a href="https://winahost.com">https://winahost.com</a>.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>1. Information Collection and Use</strong><br/>' +
                                        'We collect several types of information for various purposes to provide and improve our Service to you.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Types of Data Collected</strong>')}/>
                    </Grid>

                    <Grid item xs={11}>
                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>a. Personal Data</strong><br/>' +
                                            'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                                '<ul>' +
                                '<li>Email address</li>' +
                                '<li>First name and last name</li>' +
                                '<li>Phone number</li>' +
                                '<li>Address, City, State, Province, ZIP/Postal code</li>' +
                                '<li>Login information (Username and password)</li>' +
                                '<li>Cookies and Usage Data</li>' +
                                '</ul>'
                            )}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>b. Usage Data</strong><br/>' +
                                            'We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer\'s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>c. Cookies and Tracking Data</strong><br/>' +
                                            'We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. We use Google Tag Manager to manage tracking tags and scripts on our website. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.<br/><br/>' +
                                            'You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.')}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>2. Use of Data</strong><br/>' +
                                        'Winahost uses the collected data for various purposes:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>To provide and maintain our Service</li>' +
                            '<li>To notify you about changes to our Service</li>' +
                            '<li>To allow you to participate in interactive features of our Service when you choose to do so</li>' +
                            '<li>To provide customer care and support</li>' +
                            '<li>To provide analysis or valuable information so that we can improve the Service</li>' +
                            '<li>To monitor the usage of the Service</li>' +
                            '<li>To detect, prevent, and address technical issues</li>' +
                            '<li>To manage your access to the internal platform using login and password</li>' +
                            '<li>To personalize ads, including personalized and non-personalized ads</li>' +
                            '<li>To comply with our legal and regulatory obligations</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>3. User Consent</strong>')}/>
                    </Grid>

                    <Grid item xs={11}>
                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>a. Consent Notice</strong><br/>' +
                                            'We display a consent notice when users access our site from any EEA country. This notice clearly explains how the user\'s personal data will be used, including ad personalization and the use of cookies.')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>b. Active Consent</strong><br/>' +
                                            'Users must indicate their consent actively, for example, by clicking a button such as "Accept" or "Agree".')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>c. Third-Party Information</strong><br/>' +
                                            'We communicate which third parties (including Google) will have access to user data collected on our site. We inform users about how Google and other third parties will use their personal data, including a link to Google\'s Business Data Responsibility site.')}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>4. Data Transfer</strong><br/>' +
                                        'Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.<br/><br/>' +
                                        'If you are located outside the European Union and choose to provide information to us, please note that we transfer the data, including Personal Data, to the European Union and process it there.<br/><br/>' +
                                        'Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.<br/><br/>' +
                                        'Winahost will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other personal information.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>5. Data Disclosure</strong><br/>' +
                                        'Winahost may disclose your Personal Data in the good faith belief that such action is necessary to:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>Comply with a legal obligation</li>' +
                            '<li>Protect and defend the rights or property of Winahost</li>' +
                            '<li>Prevent or investigate possible wrongdoing in connection with the Service</li>' +
                            '<li>Protect the personal safety of users of the Service or the public</li>' +
                            '<li>Protect against legal liability</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>6. Data Security</strong><br/>' +
                                        'The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>7. Service Providers</strong><br/>' +
                                        'We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services, or to assist us in analyzing how our Service is used.<br/><br/>' +
                                        'These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>8. Links to Other Sites</strong><br/>' +
                                        'Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party\'s site. We strongly advise you to review the Privacy Policy of every site you visit.<br/><br/>' +
                                        'We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>9. Children\'s Privacy</strong><br/>' +
                                        'Our Service does not address anyone under the age of 18 ("Children").<br/><br/>' +
                                        'We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>10. Compliance with the General Data Protection Regulation (GDPR)</strong><br/>' +
                                        'If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Winahost aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.<br/><br/>' +
                                        'If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.<br/><br/>' +
                                        'In certain circumstances, you have the following data protection rights:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>The right to access, update, or delete the information we have on you.</li>' +
                            '<li>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</li>' +
                            '<li>The right to object. You have the right to object to our processing of your Personal Data.</li>' +
                            '<li>The right of restriction. You have the right to request that we restrict the processing of your personal information.</li>' +
                            '<li>The right to data portability. You have the right to receive a copy of your Personal Data in a structured, commonly used, and machine-readable format.</li>' +
                            '<li>The right to withdraw consent. You also have the right to withdraw your consent at any time where Winahost relied on your consent to process your personal information.</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('Please note that we may ask you to verify your identity before responding to such requests.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>11. Use of Cookies for Non-Personalized Ads</strong><br/>' +
                                        'If we only generate revenue from non-personalized ads, we have obtained user consent to use cookies or other locally stored data (such as mobile device identifiers) when required by law. Please note that non-personalized ads we serve on websites also require cookies to function.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>12. Limited Ads and Fraud Protection</strong><br/>' +
                                        'If we generate revenue from impressions with limited ads only, in addition to disabling the collection, sharing, and use of personal data for ad personalization, Google disables features that require the use of a local identifier, such as frequency capping. When limited programmatic ads are enabled, local storage and cookies will only be used to detect invalid traffic and protect against fraud and abuse.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>13. Certified Consent Management Platform (CMP)</strong><br/>' +
                                        'We use a Consent Management Platform (CMP) certified by the IAB and have included "Google advertising products" as a provider. We ensure that no Google Ads cookies are created without consent and that the default state of non-personalized ads is not changed if there is no consent.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>14. Changes to This Privacy Policy</strong><br/>' +
                                        'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.<br/><br/>' +
                                        'We will inform you via email and/or a prominent notice on our Service, prior to the change becoming effective, and update the "effective date" at the top of this Privacy Policy.<br/><br/>' +
                                        'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>15. Contact Us</strong><br/>' +
                                        'If you have any questions about this Privacy Policy, please contact us:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>By email: <a href="mailto:info@winahost.com">info@winahost.com</a></li>' +
                            '<li>By visiting this page on our website: <a href="https://winahost.com">https://winahost.com</a></li>' +
                            '<li>By phone: <a href="tel:+34930500225">+34 930 500 225</a></li>' +
                            '<li>By postal mail: Passeig de Gràcia, 95 - 5º 1ª, 08008 Barcelona, Spain</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default LegalENTextContent;