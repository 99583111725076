import {Grid, Link, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {WORK_SPACE} from "../../../Shared/Constants/imageConstants";

const JoinToUs = () => {

    const {t} = useTranslation('', {keyPrefix: 'usPage.joinUs'});

    const textAdvantages = `
    <ul>
        <li>
            ${t('points.point1')}
        </li>
        <li>
            ${t('points.point2')}
        </li>
        <li>
            ${t('points.point3')}
        </li>
    </ul>
    `;

    return (
        <Grid container justifyContent={'center'} mt={9} pt={9} pb={5} style={{backgroundColor: 'var(--main-winahost-gray-color)'}}>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={8}>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={10} sm={10} md={10} lg={7}>

                        <Typography variant={'h2'} fontSize={'1.7em'} mb={2} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('title'))}/>

                        <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup(t('firstParagraph'))}/>

                        <Typography variant={'body1'} mb={2} dangerouslySetInnerHTML={createMarkup(t('secondParagraph'))}/>

                        <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup(t('thirdParagraph'))}/>

                        <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup(textAdvantages)}/>

                        <Link rel={'canonical'} href={`mailto:admin@winahost.com`}
                              underline="none"
                              className={'custom-winahost-button'}
                        >
                            <span>
                                {t('contactButton')}
                            </span>
                        </Link>

                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={5} mt={5}>
                        <img src={WORK_SPACE} alt={"work_space"} width={'100%'}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default JoinToUs;
