import {Box, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {COGNITION, LOCK_PERSON, RESPONSABILITY, TRUST, USER_MAGNIFY} from "../../../Shared/Constants/iconsConstants";

interface OurValuesInterface {
    picture: string;
    title: string;
}

const OurValues = () => {

    const {t} = useTranslation('', {keyPrefix: 'usPage.ourValues'});

    const squares: OurValuesInterface[] = [
        {
            picture: USER_MAGNIFY,
            title: 'values.transparency'
        },
        {
            picture: TRUST,
            title: 'values.trust'
        },
        {
            picture: LOCK_PERSON,
            title: 'values.security'
        },
        {
            picture: COGNITION,
            title: 'values.honest'
        },
        {
            picture: RESPONSABILITY,
            title: 'values.responsability'
        }
    ];

  return (
      <Grid container justifyContent={'center'} mt={9} mb={5}>
          <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
              <Grid container justifyContent={'center'} spacing={3}>
                  <Grid item xs={11} sm={10}>
                      <Typography variant={'h1'} fontSize={'1.7em'} mb={5} fontWeight={600} textAlign={"center"}
                                  dangerouslySetInnerHTML={createMarkup(t('title'))}/>
                      <Typography variant={'body1'} mb={5} textAlign={"center"}
                                  dangerouslySetInnerHTML={createMarkup(t('subTitle'))}/>

                  </Grid>
              </Grid>
              <Grid container justifyContent={'center'} spacing={3}>
                  {
                      squares.map((square: OurValuesInterface, index: number) => {
                          return (
                              <Grid key={index} item xs={11} sm={6} md={4} lg={2}>
                                  <Box style={{
                                      backgroundColor: 'var(--main-winahost-color)',
                                      borderRadius: 30,
                                      padding: '40px 20px',
                                      textAlign: 'center'
                                  }}>
                                      <Box style={{
                                          backgroundColor: 'white',
                                          width: '100px',
                                          height: '100px',
                                          borderRadius: 30,
                                          padding: '20px 0',
                                          margin: '0 auto',
                                          textAlign: 'center'
                                      }}>
                                          <img src={square.picture} alt={"box_image"} style={{
                                              textAlign: 'center', margin: '0 auto'
                                          }}/>
                                      </Box>
                                      <Typography variant={'body1'} color={'white'} mt={5} fontWeight={600}
                                                  dangerouslySetInnerHTML={createMarkup(t(square.title))}/>

                                  </Box>
                              </Grid>
                          );
                      })
                  }
              </Grid>
          </Grid>
      </Grid>
  );
}

export default OurValues;
