import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {Checkbox, Divider, FormControlLabel, Grid, Typography} from "@mui/material";
import * as React from "react";
import CustomDialog from "../../../components/Dialog/customDialog";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {validationContactFormPage} from "../contact";
import LegalESTextContent from "../../../Shared/sharedTexts/legalESTextContent";
import {useTranslation} from "react-i18next";
import {useLanguageContext} from "../../../context/languageProvider";
import LegalENTextContent from "../../../Shared/sharedTexts/legalENTextContent";

interface props {
    contact: LeadInterface,
    validateForm: boolean,
    setValidateForm: Function,
    validationFormPage: validationContactFormPage,
    setValidationFormPage: Function
}

const ReviseForm = ({contact, validateForm, setValidateForm, validationFormPage, setValidationFormPage}: props) => {

    const {t} = useTranslation('', {keyPrefix: 'formularies.contact.reviseStep'})

    const lang = useLanguageContext();
    const legalTextContent = lang.language === 'es' ? <LegalESTextContent/> : <LegalENTextContent/>;

    const [openDialog, setOpenDialog] = React.useState(false);


    const checkedUsePoliticalAcceptance = () => {
        setValidateForm(!validateForm);
    }

    const openPoliticalPrivateDialog = () => {
        if (validateForm) {
            setValidateForm(!validateForm);
        } else {
            setOpenDialog(true);
        }
        setValidationFormPage({...validationFormPage, revise: !validateForm})
    }

    const _getAvailabilityText = (availability: string | null): string => {
        if (!availability) return '';

        switch (availability) {
            case 'fulltime':
                return t('answers.fullTime');
            case 'halftime':
                return t('answers.parcialTime');
            default:
                return '';
        }
    }

    const _getExtraText = (extras: string[] | null): string => {

        if (!extras) return '';

        let extras_translated: string[] = [];
        extras.map((extra) => {
            switch (extra) {
                case 'pool':
                    extras_translated.push('Piscina');
                    break
                case 'parking':
                    extras_translated.push('Aparcamiento');
                    break
                default:
                    break;
            }
        })

        if (extras_translated.length === 0) return '---';
        return extras_translated.join(', ')
    }


    return (
        <Grid container justifyContent={'left'} mt={3} spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'body1'} fontSize={'1.7em'} color={'black'}
                            fontWeight={400} mb={3}
                            dangerouslySetInnerHTML={createMarkup(t('title'))}/>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} sm={6} p={3}>
                    <Typography variant={'body1'} fontSize={'1.3em'} color={'black'}
                                fontWeight={500} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('propertyInformationTitle'))}/>
                    <Divider style={{backgroundColor: 'black', marginBottom: 30}}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('location'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.address)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('rooms'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup((contact.property.bedrooms) ? contact.property.bedrooms.toString() : '')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('accommodates'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup((contact.property.accommodates) ? contact.property.accommodates.toString() : '')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('bathrooms'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup((contact.property.bathrooms) ? contact.property.bathrooms.toString() : '')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('availability'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(_getAvailabilityText(contact.property.availability))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('extrasTitle'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(_getExtraText(contact.property.extras))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('questions.firstQuestionTitle'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.owner === 'yes' ? t('answers.yes') : t('answers.no'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('questions.thirdQuestionTitle'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.hire === 'yes' ? t('answers.yes') : t('answers.no'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('questions.fourthQuestionTitle'))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.licence === 'yes' ? t('answers.yes') : t('answers.no'))}/>
                </Grid>

                <Grid item xs={12} sm={6} p={3}>
                    <Typography variant={'body1'} fontSize={'1.3em'} color={'black'}
                                fontWeight={500} mb={3}
                                dangerouslySetInnerHTML={createMarkup(t('contactInformationTitle'))}/>
                    <Divider style={{backgroundColor: 'black', marginBottom: 30}}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}

                                dangerouslySetInnerHTML={createMarkup(t('contactFields.name'))}/>
                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.user.name)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}

                                dangerouslySetInnerHTML={createMarkup(t('contactFields.email'))}/>
                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.user.email)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}

                                dangerouslySetInnerHTML={createMarkup(t('contactFields.phone'))}/>
                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.user.phone)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}

                                dangerouslySetInnerHTML={createMarkup(t('winahostContact.question'))}/>
                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.call === 'yes' ? t('answers.yes') : t('answers.no'))}/>

                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} p={3}>
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={<Checkbox checked={validateForm} onClick={openPoliticalPrivateDialog}/>}
                        label={t('AcceptPrivatePolitical')}/>
                </Grid>
            </Grid>
            <CustomDialog title={t('privatePolitical')} content={legalTextContent} open={openDialog}
                          setOpen={setOpenDialog} customFunction={checkedUsePoliticalAcceptance}/>
        </Grid>
    );
}

export default ReviseForm;
