import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../utils/textUtilsResources";

const LegalESTextContent = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} mt={5} mb={5}>
                <Grid container justifyContent={'center'}>
                    <Typography variant={'h1'} fontSize={'2em'} fontWeight={'bold'}
                                dangerouslySetInnerHTML={createMarkup('Política de Privacidad de Winahost')}/>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Fecha entrada en vigor: </strong>29/08/2024'
                                    )}/>
                    </Grid>


                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('Winahost ("nosotros", "nuestro" o "nos") opera el sitio web <a href="https://winahost.com">https://winahost.com</a> (en adelante, el "Servicio").')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('Esta página tiene por objeto informar de nuestras políticas con respecto a la recopilación, uso y divulgación de datos personales cuando utiliza nuestro Servicio y las opciones que ha asociado con esos datos.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('Utilizamos sus datos para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de la información de acuerdo con esta política. A menos que se defina lo contrario en esta Política de Privacidad, los términos utilizados en esta Política de Privacidad tienen los mismos significados que en nuestros Términos y Condiciones, accesibles desde <a href="https://winahost.com">https://winahost.com</a>.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>1. Recopilación y uso de la información</strong><br/>' +
                                        'Recopilamos varios tipos de información para diversos fines con el fin de proporcionar y mejorar nuestro Servicio para usted.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Tipos de datos recopilados</strong>')}/>
                    </Grid>

                    <Grid item xs={11}>
                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>a. Datos Personales</strong><br/>' +
                                            'Al utilizar nuestro Servicio, es posible que le pidamos que nos proporcione cierta información de identificación personal que puede usarse para contactarlo o identificarlo ("Datos Personales"). La información de identificación personal puede incluir, pero no se limita a:')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                                '<ul>' +
                                '<li>DIrección de correo electrónico</li>' +
                                '<li>Nombre y apellido</li>' +
                                '<li>Número de teléfono</li>' +
                                '<li>Dirección, Ciudad, Estado, Provincia, Código Postal</li>' +
                                '<li>Información de inicio de sesión (Nombre de usuario y contraseña)</li>' +
                                '<li>Cookies y Datos de uso</li>' +
                                '</ul>'
                            )}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>b. Datos de Uso</strong><br/>' +
                                            'También podemos recopilar información sobre cómo se accede y utiliza el Servicio ("Datos de Uso"). Estos Datos de Uso pueden incluir información como la dirección de Protocolo de Internet de su computadora (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y fecha de su visita, el tiempo que pasa en esas páginas, identificadores de dispositivo y otros datos de diagnóstico.')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>c. Datos de Cookies y Seguimiento</strong><br/>' +
                                            'Utilizamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y mantener cierta información. Utilizamos Google Tag Manager para gestionar las etiquetas y scripts de seguimiento en nuestro sitio web. Las cookies son archivos con una pequeña cantidad de datos que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. Las tecnologías de seguimiento también utilizadas son balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar nuestro Servicio.<br/><br/>' +
                                            'Puede indicarle a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio.')}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>2. Uso de los datos</strong><br/>' +
                                        'Winahost utiliza los datos recopilados para diversos fines:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>Proporcionar y mantener nuestro Servicio</li>' +
                            '<li>Notificarle sobre cambios en nuestro Servicio</li>' +
                            '<li>Permitirle participar en funciones interactivas de nuestro Servicio cuando elija hacerlo</li>' +
                            '<li>Proporcionar atención y soporte al cliente</li>' +
                            '<li>Proporcionar análisis o información valiosa para que podamos mejorar el Servicio</li>' +
                            '<li>Monitorear el uso del Servicio</li>' +
                            '<li>Detectar, prevenir y abordar problemas técnicos</li>' +
                            '<li>Gestionar su acceso a la plataforma interna mediante el uso de login y contraseña</li>' +
                            '<li>Personalizar anuncios, incluidos anuncios personalizados y no personalizados</li>' +
                            '<li>Cumplir con nuestras obligaciones legales y regulatorias</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>3. Consentimiento del usuario</strong>')}/>
                    </Grid>

                    <Grid item xs={11}>
                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>a. Aviso de consentimiento</strong><br/>' +
                                            'Mostramos un aviso de consentimiento cuando los usuarios acceden a nuestro sitio desde cualquiera de los países del EEE. Este aviso explica claramente cómo se usarán los datos personales del usuario, incluida la personalización de anuncios y el uso de cookies.')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>b. Consentimiento activo</strong><br/>' +
                                            'Los usuarios deben indicar su consentimiento de forma activa, por ejemplo, haciendo clic en un botón como "Aceptar" o "De acuerdo".')}/>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('<strong>c. Información sobre terceros</strong><br/>' +
                                            'Comunicamos qué terceros (incluido Google) tendrán acceso a los datos de usuario que se recogen en nuestro sitio. Informamos a los usuarios sobre cómo Google y otros terceros utilizarán sus datos personales, incluyendo un enlace al Sitio sobre Responsabilidad de Datos Empresariales de Google.')}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>4. Transferencia de datos</strong><br/>' +
                                        'Su información, incluidos los Datos Personales, puede ser transferida a — y mantenida en — computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.<br/><br/>' +
                                        'Si se encuentra fuera de la Unión Europea y decide proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los Datos Personales, a la Unión Europea y los procesamos allí.<br/><br/>' +
                                        'Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.<br/><br/>' +
                                        'Winahost tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de manera segura y de acuerdo con esta Política de Privacidad y no se realizará ninguna transferencia de sus Datos Personales a una organización o país a menos que existan controles adecuados, incluidos la seguridad de sus datos y otra información personal.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>5. Divulgación de datos</strong><br/>' +
                                        'Winahost puede divulgar sus Datos Personales en la creencia de buena fe de que dicha acción es necesaria para:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>Cumplir con una obligación legal</li>' +
                            '<li>Proteger y defender los derechos o propiedad de Winahost</li>' +
                            '<li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>' +
                            '<li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>' +
                            '<li>Protegerse contra la responsabilidad legal</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>6. Seguridad de los datos</strong><br/>' +
                                        'La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su absoluta seguridad.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>7. Proveedores de servicios</strong><br/>' +
                                        'Podemos emplear a terceras empresas y personas para facilitar nuestro Servicio ("Proveedores de Servicios"), para proporcionar el Servicio en nuestro nombre, para realizar servicios relacionados con el Servicio o para ayudarnos a analizar cómo se utiliza nuestro Servicio.<br/><br/>' +
                                        'Estos terceros tienen acceso a sus Datos Personales sólo para realizar estas tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para ningún otro propósito.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>8. Enlaces a otros sitios</strong><br/>' +
                                        'Nuestro Servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.<br/><br/>' +
                                        'No tenemos control sobre el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>9. Privacidad de los niños</strong><br/>' +
                                        'Nuestro Servicio no se dirige a menores de 18 años ("Niños").<br/><br/>' +
                                        'No recopilamos a sabiendas información de identificación personal de menores de 18 años. Si usted es un padre o tutor y es consciente de que su hijo nos ha proporcionado Datos Personales, por favor contáctenos. Si nos damos cuenta de que hemos recopilado Datos Personales de niños sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>10. Cumplimiento del Reglamento General de Protección de Datos (GDPR)</strong><br/>' +
                                        'Si usted es residente del Espacio Económico Europeo (EEE), tiene ciertos derechos de protección de datos. Winahost tiene como objetivo tomar medidas razonables para permitirle corregir, modificar, eliminar o limitar el uso de sus Datos Personales.<br/><br/>' +
                                        'Si desea ser informado sobre qué Datos Personales tenemos sobre usted y si desea que sean eliminados de nuestros sistemas, por favor contáctenos.<br/><br/>' +
                                        'En ciertas circunstancias, usted tiene los siguientes derechos de protección de datos:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>El derecho a acceder, actualizar o eliminar la información que tenemos sobre usted.</li>' +
                            '<li>El derecho de rectificación. Tiene derecho a que se rectifique su información si esa información es inexacta o incompleta.</li>' +
                            '<li>El derecho a oponerse. Tiene derecho a oponerse a nuestro procesamiento de sus Datos Personales.</li>' +
                            '<li>El derecho de restricción. Tiene derecho a solicitar que restrinjamos el procesamiento de su información personal.</li>' +
                            '<li>El derecho a la portabilidad de los datos. Tiene derecho a recibir una copia de sus Datos Personales en un formato estructurado, de uso común y legible por máquina.</li>' +
                            '<li>El derecho a retirar el consentimiento. También tiene derecho a retirar su consentimiento en cualquier momento donde Winahost haya confiado en su consentimiento para procesar su información personal.</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('Tenga en cuenta que es posible que le pidamos que verifique su identidad antes de responder a dichas solicitudes.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>11. Uso de cookies para anuncios no personalizados</strong><br/>' +
                                        'Si solo obtenemos ingresos con anuncios no personalizados, hemos obtenido el consentimiento de los usuarios para usar cookies u otros datos almacenados localmente (como identificadores de dispositivos móviles) cuando lo exige la ley. Tenga en cuenta que los anuncios no personalizados que publicamos en los sitios web también necesitan cookies para funcionar.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>12. Anuncios limitados y protección contra fraude</strong><br/>' +
                                        'Si obtenemos ingresos con impresiones solo con anuncios limitados, además de inhabilitar la recopilación, la opción de compartir y el uso de datos personales para personalizar anuncios, Google inhabilita las funciones que requieren utilizar un identificador local, como la limitación de frecuencia. Cuando estén activados los anuncios programáticos limitados, se usarán el almacenamiento local y las cookies sólo para detectar tráfico no válido y proteger frente a fraudes y abusos.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>13. Plataforma de Gestión de Consentimiento (CMP) Certificada</strong><br/>' +
                                        'Utilizamos una Plataforma de Gestión de Consentimiento (CMP) certificada por la IAB y hemos incluido "productos publicitarios de Google" como proveedor. Nos aseguramos de que no se creen cookies de Google Ads sin consentimiento y que el estado predeterminado de los anuncios no personalizados no se cambie si no hay consentimiento.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>14. Cambios a esta Política de Privacidad</strong><br/>' +
                                        'Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.<br/><br/>' +
                                        'Le informaremos por correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigor y actualizaremos la "fecha de entrada en vigor" en la parte superior de esta Política de Privacidad.<br/><br/>' +
                                        'Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página.')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>15. Contáctenos</strong><br/>' +
                                        'Si tiene alguna pregunta sobre esta Política de Privacidad, por favor contáctenos:')}/>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup('' +
                            '<ul>' +
                            '<li>Por correo electrónico: <a href="mailto:info@winahost.com">info@winahost.com</a></li>' +
                            '<li>Visitando esta página en nuestro sitio web: <a href="https://winahost.com">https://winahost.com</a></li>' +
                            '<li>Por teléfono: <a href="tel:+34930500225">+34 930 500 225</a></li>' +
                            '<li>Por correo postal: Passeig de Gràcia, 95 - 5º 1ª, 08008 Barcelona, España</li>' +
                            '</ul>'
                        )}/>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default LegalESTextContent;
