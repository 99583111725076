import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useTranslation} from "react-i18next";

const ShowErrorMessageRentalizeForm = () => {

    const {t} = useTranslation('', {keyPrefix: 'formularies.price.rentalizer.errorMessages'})

    return (
        <Grid container justifyContent={'center'} mb={4}>
            <Grid container justifyContent={'center'} pl={2} pr={4}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontSize={'1.3em'} mb={3} fontWeight={600} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup(t('cannotShowData'))} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={'1.3em'} mb={1} fontWeight={500} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup(t('sendEmail'))} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={'1.3em'} mb={1} fontWeight={500} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup(t('callPhone'))} textAlign={'center'}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ShowErrorMessageRentalizeForm;
