import { Button, Grid, Typography } from "@mui/material";
import { createMarkup } from "../../../../Shared/utils/textUtilsResources";
import CustomTextField from "../../../../components/customComponents/customTextField";
import { ChangeEvent, useEffect, useState } from "react";
import * as React from "react";
import { LeadInterface } from "../../../../interfaces/leadInterface";
import { changeLeadHookData } from "../../../../hooks/leadsHook";
import { validationPhone, validationTextNotEmpty } from "../../../../Shared/utils/validations";

interface Props {
    lead: LeadInterface,
    setLead: Function,
    currentFunction: Function
}

const QuickContactUserDataForm = ({ lead, setLead, currentFunction }: Props) => {
    const [sendButton, setSendButton] = useState(true);

    const availableSendButton = () => {
        const dataNameValid = validationTextNotEmpty(lead.user.name);
        const dataPhoneValid = validationPhone(lead.user.phone);
        // El botón se habilita solo si ambos campos son válidos
        setSendButton(!(dataNameValid && dataPhoneValid));
    };

    const changeUserData = (key: string, value: string) => {
        changeLeadHookData(lead, setLead, key, value);
    };

    useEffect(() => {
        availableSendButton();
    }, [lead]);

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} textAlign={'center'}>
                <Typography variant={'body1'} mb={5} color={'black'} fontSize={'2em'} fontWeight={600}
                            dangerouslySetInnerHTML={createMarkup('Introduzca sus datos, enseguida nos pondremos en contacto con usted')}>
                </Typography>

                <Grid container justifyContent={'center'} spacing={3} mb={3}>
                    <Grid item xs={12} md={6}>
                        <CustomTextField fullWidth
                                         required={true}
                                         value={lead.user.name}
                                         label={'Nombre completo'}
                                         id="userContactFormNameDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('name', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomTextField fullWidth
                                         required={true}
                                         value={lead.user.phone}
                                         label={'Teléfono'}
                                         id="userContactFormPhoneDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('phone', e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Button className={'custom-winahost-button'}
                        disabled={sendButton}
                        onClick={() => {
                            currentFunction();
                        }}>
                    <span style={{ fontWeight: 'bold', padding: '0 50px' }}>
                        {'Envía'}
                    </span>
                </Button>
            </Grid>
        </Grid>
    );
}

export default QuickContactUserDataForm;
