import Box from "@mui/material/Box";
import {Button, Drawer, Link, List, ListItem, Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import { PathRouteInterface } from "../navbar/NavBarPages";
import { WINAHOST_HORIZONTAL_WHITE_LOGO } from "../../Shared/Constants/imageConstants";
import { useTranslation } from "react-i18next";
import {useLanguageContext} from "../../context/languageProvider";
import {LANG} from "../../Shared/Constants/LangConstants";
import {MAIN_ROUTE} from "../../routes/constantsRoutes";
import {useNavigate} from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";

interface props {
    openDrawer: boolean,
    closeDrawer: Function,
    pages: Array<PathRouteInterface>,
    lang: string
}

const SideMenuDrawer = ({ openDrawer, closeDrawer, pages }: props) => {
    const { t } = useTranslation();

    const lang = useLanguageContext();
    const navigate = useNavigate();


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getCurrentRoute = (language: string) => {
        navigate(language === 'es' ? MAIN_ROUTE :  `/${language}`);
        window.location.reload();
    }


    return (
        <Drawer anchor={'left'} open={openDrawer}>
            <Box
                role="presentation"
                className="drawerBoxPresentation"
            >
                <Grid container direction="column" style={{ height: "100%" }}>
                    <Grid item xs>
                        <List style={{ flexGrow: 1 }}>
                            <ListItem key={'main_page'}>
                                <Link rel={'canonical'} href={'/'}>
                                    <img src={WINAHOST_HORIZONTAL_WHITE_LOGO}
                                         alt={"logo winahost"}
                                         style={{ width: '190px' }}
                                    />
                                </Link>
                            </ListItem>
                            {pages.map(page => (
                                <ListItem key={page.id} style={{ margin: 15 }}>
                                    <Link rel={'canonical'} href={page[`path_${lang.language}` as keyof typeof page]} target={page.target}
                                          style={{ color: 'white' }}>
                                        {t(page.name)}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{
                            fontSize: 16,
                            color: 'white',
                            textAlign: 'center',
                            margin: '10px 10px 40px 0',
                            padding: '5px 5px 0 0'
                        }}
                    >
                        <LanguageIcon
                            sx={{ fontSize: 40, color: '#fff' }}
                        />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                    >
                        {LANG.map((language) => {
                            if (language.shortLang === lang.language) return;

                            return (
                                <MenuItem onClick={() => getCurrentRoute(language.shortLang)}>
                                    <Typography>{language.langName}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                        <Button onClick={() => closeDrawer()} >
                            <span
                                style={{
                                    fontSize: 16,
                                    color: 'white',
                                    textAlign: 'center',
                                    margin: '10px 10px 0 0',
                                    padding: '5px 5px 0 0'
                                }}
                            >
                                Close
                            </span>
                        </Button>
                </Grid>
            </Box>
        </Drawer>
    );
}

export default SideMenuDrawer;
