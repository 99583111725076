import {Grid, Typography} from "@mui/material";
import ReviewComponent from "./components/reviewComponent";
import React from "react";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import Reviews, {ReviewInterface} from "./components/reviews";

const ReviewClientsTrustBox = () => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.reviewClientsTrustBox'});

    const reviews = Reviews();

    return (
        <Grid container justifyContent={'center'} pt={5} pb={1}
              style={{backgroundColor: 'var(--main-winahost-gray-color)'}}>
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10} sm={11} md={9} lg={9} textAlign={'center'}>
                        <Typography variant="body1" fontSize={'1.7rem'} mb={3} fontWeight={600} color={'black'}
                                    dangerouslySetInnerHTML={createMarkup(t('reviewClientsTrustBoxTitle'))}/>
                    </Grid>
                    <Grid item xs={10} sm={11} md={11} lg={11}>
                        <Grid container justifyContent={'center'} spacing={5}>
                            {reviews.map((review: ReviewInterface, index: number) => {
                                return (
                                    <Grid key={index} item xs={10} sm={6} md={3} lg={3}>
                                        <ReviewComponent review={review}/>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default ReviewClientsTrustBox;
