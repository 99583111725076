import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


const ExampleInvoice = () => {

    const {t} = useTranslation('', {keyPrefix: "pricesPage.example"});

    return (
        <Grid container justifyContent={'center'} mt={5}>
            <Grid item xs={11} sm={11} md={11} lg={9} xl={8}>
                <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Grid item xs={11} sm={11} md={11} lg={9} xl={8}>
                        <Typography variant={'h2'} mb={3} fontSize={'1.7em'} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('title'))}/>
                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('subTitle'))}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} sm={11} md={10} lg={10} xl={9} className={'tableInvoiceBorderExample'}>
                        <table className={'tableInvoiceExample'}>
                            <thead>
                            <tr>
                                <th>{t('table.priceNight')}</th>
                                <th>{t('table.nights')}</th>
                                <th></th>
                                <th>{t('table.rentalIncome')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>170€</td>
                                <td>7</td>
                                <td>=</td>
                                <td>1190€</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>{t('table.premiumRate')}</td>
                                <td>273€</td>
                            </tr>
                            <tr>
                                <td colSpan={4} className={'adviceLastRow'}>
                                    <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup(t('table.cleanRate'))}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} mt={5} textAlign={"right"} pr={7}>
                                <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup(t('table.ownerIncome'))}/>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} textAlign={"center"} pl={2} pr={2}>
                                <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup(t('table.optimalPrices'))}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ExampleInvoice;
