import {Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
    EN_PRICES,
    EN_PROFESSIONAL,
    ENGLISH_MAIN_ROUTE,
    ES_PRICES,
    ES_PROFESSIONAL
} from "../../../routes/constantsRoutes";
import {useLanguageContext} from "../../../context/languageProvider";



const ProfessionalCard = ({priceBoxHeight}: { priceBoxHeight: number }) => {

    const lang = useLanguageContext();
    const {t} = useTranslation('', {keyPrefix: 'pricesPage.cards.professional'});

    const pathFee = lang.language === 'es' ? ES_PRICES + ES_PROFESSIONAL : ENGLISH_MAIN_ROUTE + EN_PRICES + EN_PROFESSIONAL;

    const textProperties = [
        'content.realEstateInvestment',
        'content.touristRentalOutsourcing',
        'content.buildingPropertyManagement',
        'content.realEstateDevelopmentsManagement',
        'content.assetManagementCompanies',
        'content.realEstateManagement',
        'content.profitabilityAnalysis',
    ];

    return (
        <Grid item xs={12} sm={12} md={4}>
            <Paper
                className={'priceBoxContent'}
                style={{
                    backgroundColor: 'rgba(113,170,170,0.4)',
                    borderRadius: 30,
                    padding: '0 40px 20px 40px',
                    height: `${priceBoxHeight === 0 ? 'auto' : priceBoxHeight + 'px'}`

                }} elevation={5}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} fontWeight={500}
                                    dangerouslySetInnerHTML={createMarkup(t('title'))}/>
                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('subtitle'))}/>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant={'body1'} fontSize={'.8em'} textAlign={'justify'}
                                    dangerouslySetInnerHTML={createMarkup(t('littleText'))}/>

                    </Grid>
                    <Grid item xs={12} mt={2}>
                        {textProperties.map((property: string, index: number) => {
                            return (
                                <Grid key={index} container justifyContent={'center'} mt={1.5}>
                                    <Grid item xs={1}>
                                        <CheckCircleOutlineIcon/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography key={index} variant={'body1'}
                                                    dangerouslySetInnerHTML={createMarkup(t(property))}/>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12} mt={5} mb={3}>
                        <Link rel={'canonical'} key={'main_page'} href={pathFee}
                              underline="none"
                              className={'custom-winahost-button priceCardButton'}
                        >
                                        <span style={{fontWeight: 'bold'}}>
                                            {t('buttonStart')}
                                        </span>
                        </Link>
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
    );
}

export default ProfessionalCard;
