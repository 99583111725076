import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {WORLD_BALL} from "../../../Shared/Constants/imageConstants";

const MeetWinahost = () => {

    const {t} = useTranslation('', {keyPrefix: 'usPage.meetWinahost'});

    return (
        <Grid container justifyContent={'center'} mt={9} mb={5}>
            <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={'h2'} fontSize={'1.7em'} mb={2} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('title'))}/>

                        <Typography variant={'body1'} fontWeight={500} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('firstParagraph'))}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('secondParagraph'))}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('thirdParagraph'))}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('fourthParagraph'))}/>


                    </Grid>
                    <Grid item xs={12} sm={9} md={6} lg={6} style={{margin: 'auto auto', textAlign: 'center'}}>
                            <img src={WORLD_BALL} alt={"bedroom_house"} width={'90%'} style={{borderRadius: 30, margin: 'auto auto', textAlign: 'center'}}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MeetWinahost;
