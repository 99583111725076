import {Grid, Link, Typography} from "@mui/material";
import '../../../Styles/historiesCssBox.css';
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {EN_CONTACT, ENGLISH_MAIN_ROUTE, ES_CONTACT} from "../../../routes/constantsRoutes";
import {FAMILY_TENT} from "../../../Shared/Constants/imageConstants";
import {useLanguageContext} from "../../../context/languageProvider";


const Histories = () => {

    const lang = useLanguageContext();
    const {t} = useTranslation('', {keyPrefix: 'mainPage.histories'});

    const servicePath = lang.language === 'es' ? ES_CONTACT : ENGLISH_MAIN_ROUTE + EN_CONTACT;

    return (
        <Grid container justifyContent={'center'} mt={9} mb={9}>
           <Grid item xs={12} md={8}>
               <Grid container justifyContent={'center'}>
                   <Grid item xs={10} sm={6} md={3} lg={3}>
                       <img src={FAMILY_TENT}
                            alt={"family_tent"}
                            style={{width: '100%', borderRadius: 30}}

                       />
                   </Grid>
                   <Grid item xs={12} md={9} lg={9} p={6} alignItems={'center'}>
                       <Typography variant={'h2'} fontSize={'1.7em'} mb={5} fontWeight={600}
                                   dangerouslySetInnerHTML={createMarkup(t('historiesTitle'))}>
                       </Typography>
                       <Typography variant={'body1'} mb={9}
                                   dangerouslySetInnerHTML={createMarkup(t('historiesBody'))}>
                       </Typography>

                       <Link rel={'canonical'}  key={'main_page'} href={`${servicePath}`}
                             underline="none"
                             className={'custom-winahost-button'}>
                                <span className={'btnBeginToRentalize'}>
                                    {t('historiesButton')}
                                </span>
                       </Link>
                   </Grid>
               </Grid>
           </Grid>
        </Grid>
    );
}

export default Histories;
