import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


const ProfitableProperty = () => {

    const {t} = useTranslation('', {keyPrefix: 'pricesPage.profitableProperty'});

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} style={{textAlign: 'center'}}>
                <Typography variant="h2" fontSize={'1.7em'} mb={4} fontWeight={700} color={'var(--main-winahost-color)'}
                            style={{marginTop: 60}}
                            dangerouslySetInnerHTML={createMarkup(t('profitablePropertyTitle'))}/>
                <Typography variant={'body1'} mb={5}
                            dangerouslySetInnerHTML={createMarkup(t('profitablePropertyBody'))}/>
            </Grid>
        </Grid>
    );
}

export default ProfitableProperty;
