import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {createMarkup} from "../utils/textUtilsResources";

const DiscoverHowWeCalculated = () => {

    const {t} = useTranslation('', {keyPrefix: 'formularies.price.rentalizer.successMessages'});

    return (
        <Grid container justifyContent={'center'}>
            <Grid xs={11}>

                <Grid container justifyContent={'center'}>
                    <Grid xs={12}>
                        <div>
                            <Typography variant="body1" dangerouslySetInnerHTML={createMarkup(t('discoverHowWeCalculate'))}/>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DiscoverHowWeCalculated;
