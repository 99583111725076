import {Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { yellow } from '@mui/material/colors';
import {EN_PRICES, ENGLISH_MAIN_ROUTE, ES_PRICES, PREMIUM} from "../../../routes/constantsRoutes";
import {useLanguageContext} from "../../../context/languageProvider";



const PremiumCard = ({priceBoxHeight}: { priceBoxHeight: number }) => {

    const lang = useLanguageContext();
    const {t} = useTranslation('', {keyPrefix: 'pricesPage.cards.premium'});

    const pathFee = lang.language === 'es' ? ES_PRICES + PREMIUM : ENGLISH_MAIN_ROUTE + EN_PRICES + PREMIUM;

    const textProperties = [
        'content.bookingManagement',
        'content.checkInCheckOut',
        'content.cleaningLaundry',
        'content.maintenance',
        'content.listingPlatforms',
        'content.pricingOptimization',
        'content.customerSupport',
        'content.multilingualCommunication',
        'content.exclusiveGuestServices',
        'content.winahostAdvisor',
        'content.premiumPositioning',
        'content.ownerAppPlatform',
        'content.bigData',
    ];

    return (
        <Grid item xs={12} sm={12} md={4}>
            <Paper
                className={'priceBoxContent'}
                style={{
                    backgroundColor: 'var(--main-winahost-color)',
                    borderRadius: 30,
                    padding: '0 40px 20px 40px',
                    height: `${priceBoxHeight === 0 ? 'auto' : priceBoxHeight + 'px'}`
                }}
                elevation={3}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} color={'white'} fontWeight={500}
                                    dangerouslySetInnerHTML={createMarkup(t('title'))}/>

                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} color={'white'} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('percent'))}/>
                        <Typography variant={'body1'} fontSize={'.7em'} color={'white'} fontWeight={400}
                                    dangerouslySetInnerHTML={createMarkup(t('littleText'))}/>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        {textProperties.map((property: string, index: number) => {
                            return (
                                <Grid key={index} container justifyContent={'center'} mt={1.5}>
                                    <Grid item xs={1}>
                                        <CheckCircleOutlineIcon sx={{ color: yellow[50] }}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography key={index} variant={'body1'} color={'white'}
                                                    dangerouslySetInnerHTML={createMarkup(t(property))}/>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={5} mb={3}>
                    <Link rel={'canonical'} key={'main_page'} href={pathFee}
                          underline="none"
                          className={'custom-winahost-white-button priceCardButton'}
                    >
                            <span style={{fontWeight: 'bold'}}>
                                {t('buttonStart')}
                            </span>
                    </Link>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default PremiumCard;
