import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import SimpleSkeleton from "../skeletons/simpleSkeleton";
import CustomWinahostSlider from "../customComponents/customWinahostSlider";
import {useEffect, useState} from "react";
import * as React from "react";
import {formatNumber} from "../../Shared/utils/formatNumbers";
import CustomDialog from "../Dialog/customDialog";
import DiscoverHowWeCalculated from "../../Shared/sharedTexts/discoverHowWeCalculated";
import {useTranslation} from "react-i18next";


interface props {
    revenueProperty: any | null,
}

const CalculateSuccessForm = ({revenueProperty}: props) => {

    const {t} = useTranslation('', {keyPrefix: 'formularies.price.rentalizer.successMessages'});

    const [openDialog, setOpenDialog] = React.useState(false);

    const [calculateRevenueDay, setCalculateRevenueDay] = useState<number>(0);
    const [range, setRange] = useState({
        min: 0,
        medium: 0,
        max: 0
    });

    if (!revenueProperty) return <SimpleSkeleton/>;

    const _calculateDayFromRevenueAndAdr = () => {
        const revenueData: string = revenueProperty.property_stats.revenue.ltm;
        const adrData: string = revenueProperty.property_stats.adr.ltm;
        return parseFloat((parseFloat(revenueData) / parseFloat(adrData)).toFixed(2));
    }

    const calculateValueOfRevenueDay = (numberOfDays: number|null = null): void => {
        const days = numberOfDays ?? _calculateDayFromRevenueAndAdr();
        setCalculateRevenueDay(parseFloat((days * parseFloat(revenueProperty.property_stats.adr.ltm.toFixed())).toFixed(2)));
    }

    const calculateRange = () => {

        const days = _calculateDayFromRevenueAndAdr();

        const min = parseInt((days * 0.9).toFixed());
        const max = parseInt((days * 1.3).toFixed());

        setRange({
            min: min,
            medium: parseInt(days.toFixed()),
            max: max
        })
    }

    const handleChange = (event: Event, value: number | number[]) => {
        if (typeof value === 'number') {
            setRange({...range, medium: value})
            calculateValueOfRevenueDay(value);
        }
    };

    useEffect(() => {
        calculateRange();
        calculateValueOfRevenueDay(parseInt(_calculateDayFromRevenueAndAdr().toFixed()));
    }, []);

    return (
        <Grid container justifyContent={'center'} mb={4}>
            <Grid container justifyContent={'center'} pl={2} pr={4}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontSize={'1.7em'} mb={1} fontWeight={500} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup(t('winahostTrust'))} textAlign={'center'}/>
                    <Typography variant="body1" mb={1} fontWeight={400}
                                dangerouslySetInnerHTML={createMarkup(t('anualIncome'))} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={'2.5em'} mb={1} fontWeight={700} textAlign={'center'}
                                style={{color: 'var(--main-winahost-color)'}}
                                dangerouslySetInnerHTML={createMarkup(`${formatNumber(calculateRevenueDay)} ${revenueProperty.property_details.currency_symbol}`)}/>
                    <Typography variant="body1" mb={1} fontWeight={400}
                                dangerouslySetInnerHTML={createMarkup(
                                    t('nightsTo', {"rangeMedium": range.medium, "revenueProperty": `${(revenueProperty.property_stats.adr.ltm).toFixed(2)} ${revenueProperty.property_details.currency_symbol}`})
                                )} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={14} mb={1} style={{color: 'gray'}}
                                dangerouslySetInnerHTML={createMarkup(t('calculEstimate'))}/>

                    <CustomWinahostSlider defaultValue={range.medium}
                                          value={range.medium}
                                          aria-label="Default"
                                          valueLabelDisplay="auto"
                                          min={range.min}
                                          max={range.max}
                                          aria-labelledby="continuous-slider"
                                          onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                    <Button onClick={() => setOpenDialog(true)} style={{textDecoration: 'underline'}}>
                        <Typography variant={'body1'} fontSize={'1em'} color={'var(--main-winahost-color)'}
                                    fontWeight={300} className={'footerQuickLinksParagraph'} textAlign={'center'}
                                    dangerouslySetInnerHTML={createMarkup(t('discoverToCalculate'))}/>
                    </Button>
                </Grid>
            </Grid>
            <CustomDialog title={t('titleIncomeDiscover')} content={<DiscoverHowWeCalculated/>} open={openDialog}
                          setOpen={setOpenDialog} customFunction={null}/>
        </Grid>
    );
}

export default CalculateSuccessForm;
