import * as React from "react";
import {Grid} from "@mui/material";
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import ScreenSizeResource from "../../Shared/utils/screenSizeResource";
import './horizontalScroll.css';
import {useTranslation} from "react-i18next";

interface props {
    arrayList: any[],
    component: any
}

const HorizontalScroll = ({arrayList, component}: props) => {

    const {width} = ScreenSizeResource();
    const {t} = useTranslation('', {keyPrefix: "resourcesPage.blogs"});

    return (
        <ScrollMenu
            LeftArrow={(width < 700) ? null : LeftArrow}
            RightArrow={(width < 700) ? null : RightArrow}
        >
            {arrayList.map((obj: any, index: number) => (
                <Card key={index}
                      itemId={obj.id.toString()} // NOTE: itemId is required for track items
                      obj={obj}
                      RjxElement={component}
                />
            ))}
        </ScrollMenu>
    );
}

function LeftArrow() {
    const {t} = useTranslation('', {keyPrefix: "resourcesPage.blogs"});

    const {initComplete, isFirstItemVisible, scrollPrev} =
        React.useContext(VisibilityContext);

    return (
        <Arrow
            disabled={!initComplete || (initComplete && isFirstItemVisible)}
            onClick={() => scrollPrev()}
            className="left"
        >
            {t('previousButton')}
        </Arrow>
    );
}

function RightArrow() {
    const {t} = useTranslation('', {keyPrefix: "resourcesPage.blogs"});
    const {initComplete, isLastItemVisible, scrollNext} =
        React.useContext(VisibilityContext);

    return (
        <Arrow
            disabled={initComplete && isLastItemVisible}
            onClick={() => scrollNext()}
            className="right"
        >
            {t('nextButton')}
        </Arrow>
    );
}

function Arrow({
                   children,
                   disabled,
                   onClick,
                   className,
               }: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
    className?: String;
}) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={'arrow' + `-${className}`}
            style={{
                height: 40,
                cursor: 'pointer',
                display: 'flex',
                backgroundColor: 'var(--main-winahost-color)',
                color: "white",
                borderRadius: 30,
                flexDirection: 'column',
                justifyContent: 'center',
                right: '1%',
                opacity: disabled ? '0' : '1',
                userSelect: 'none',
            }}
        >
            {children}
        </button>
    );
}

function Card(
    {
        itemId,
        RjxElement,
        obj,
    }: {
        itemId: string;
        RjxElement: any;
        obj: any;
    }) {

    return (
        <div
            data-cy={itemId}
            style={{
                display: 'inline-block',
                margin: '40px 10px 0 10px',
                width: '300px',
                userSelect: 'none',
            }}
            tabIndex={0}
        >
            <Grid item xs={12}>
                <RjxElement
                    {...obj}
                />
            </Grid>
        </div>
    );
}

export default HorizontalScroll;
