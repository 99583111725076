import React, {useState} from "react";
import {
    Box,
    Typography,
    Grid,
} from "@mui/material";
import {LeadInterface} from "../../interfaces/leadInterface";
import {leadFormContactAtBase} from "../../Shared/baseData/leadBaseData";
import {formatLeadMergeData} from "../../hooks/leadsHook";
import {upsertHubspotContact} from "../../Shared/hooks/upsertHubspotContact";
import {gtagLandingContactAtConversion, metaLeadTrack} from "../../hooks/socialMeditionTags";
import {validationEmail, validationPhone, validationTextNotEmpty} from "../../Shared/utils/validations";
import FormContactAtSuccessfullyResponse from "./formContactAtSuccessfullyResponse";
import FormCreateLeadContractAt from "./formCreateLeadContractAt";
import {FormErrors} from "./Interfaces/errorsLeadInterface";
const FormularioAt = () => {
    const [lead, setLead] = useState<LeadInterface>(leadFormContactAtBase);
    const [errors, setErrors] = useState<FormErrors>({});
    const [usePolitical, setUsePolitical] = useState<boolean>(false);
    const [showSuccessfullyForm, setShowSuccessfullyForm] = useState<boolean>(false);
    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};
        if (validationTextNotEmpty(lead.user.name)) newErrors.name = "El nombre no es válido";
        if (validationPhone(lead.user.phone)) newErrors.phone = "El teléfono no es válido";
        if (validationEmail(lead.user.email)) newErrors.email = "El email no es válido"
        if (!usePolitical) {
            newErrors.political_acceptance = "Debes aceptar la política de privacidad.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async () => {
        if (validateForm()) {
            metaLeadTrack();
            gtagLandingContactAtConversion();
            const leadMergeData = formatLeadMergeData(lead);
            upsertHubspotContact(leadMergeData);
            setShowSuccessfullyForm(true);
        }
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "40px",
                maxWidth: "1200px",
                margin: "0 auto",
                borderRadius: "8px",
                background: "linear-gradient(to bottom, #FFFFFF, #367E7F)",
                fontFamily: "'Poppins', sans-serif",
                textAlign: "left",
                paddingTop: "50px",
                paddingBottom: "30px",
            }}
        >
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Box sx={{ paddingRight: { xs: "0", md: "40px" } }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: "1rem", sm: "1.2rem" },
                                fontWeight: 400,
                                color: "#008080",
                                textAlign: "left",
                                marginBottom: "8px",
                            }}
                        >
                            ¿Hablamos sobre tus objetivos?
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: { xs: "1.5rem", sm: "1.8rem" },
                                fontWeight: 600,
                                color: "#000",
                                textAlign: "left",
                                marginBottom: "24px",
                            }}
                        >
                            Ponte cómodo y cuéntanos un poco más
                        </Typography>
                    </Box>
                </Grid>

                {showSuccessfullyForm ? (
                    <FormContactAtSuccessfullyResponse />
                ) : (
                    <FormCreateLeadContractAt
                        lead={lead}
                        setLead={setLead}
                        formSubmit={handleSubmit}
                        errors={errors}
                        usePolitical={usePolitical}
                        setUsePolitical={setUsePolitical}
                    />
                )}
            </Grid>
        </Box>
    );
};

export default FormularioAt;