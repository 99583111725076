import React, {ChangeEvent, useState} from "react";
import {Alert, Button, CircularProgress, Grid, InputAdornment} from "@mui/material";
import AuthLayout from "./authLayout";
import CustomTextField from "../../components/customComponents/customTextField";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {loginOfOwner} from "./hooks/loginOwnerAuthentificator";
import {RECOVERY_PASSWORD_OWNERS} from "../../routes/constantsRoutes";
import {useTranslation} from "react-i18next";

const LoginPage = () => {

    const {t} = useTranslation('', {keyPrefix: 'loginPage'});

    const [owner, setOwner] = useState({
        'email': '',
        'password': '',
    });

    const [visibleIconPassword, setVisibleIconPassword] = useState<JSX.Element>(<VisibilityOffIcon/>);
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState<string>('');
    const [displayLoader, setDisplayLoader] = useState<boolean>(false);

    const showOrHidePassword = () => {
        setVisiblePassword(!visiblePassword);
        if (visiblePassword) {
            setVisibleIconPassword(<VisibilityOffIcon/>);
        } else {
            setVisibleIconPassword(<VisibilityIcon/>);
        }
    }

    const fillOwnerProperties = (field: string, value: string) => {
        setOwner({...owner, [field]: value});
    }

    const loginForm = () => {
        setDisplayLoader(true);
        setDisplayError(false);
        setDisplayErrorMessage('');

        loginOfOwner(owner).then(response => {
            if (response.code === 500) {
                setDisplayLoader(false);
                setDisplayError(true);
                setDisplayErrorMessage((response.data.message === 'Bad credentials' ? 'checkEmail' : 'propertyNotExist'));
            }
            if (response?.data.webapp) {
                setDisplayLoader(false);
                window.location = response.data.webapp;
            }
        });
    }

    return (
        <AuthLayout>
            <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>
                <Grid item xs={11}>
                    <CustomTextField
                        fullWidth
                        size={'small'}
                        required={true}
                        label={t('email')}
                        type={'email'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => fillOwnerProperties('email', e.target.value)}
                    />

                </Grid>

                <Grid item xs={11} mt={1}>
                    <CustomTextField
                        fullWidth
                        size={'small'}
                        required={true}
                        label={t('password')}
                        type={visiblePassword ? 'text' : 'password'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => fillOwnerProperties('password', e.target.value)}
                        helperText={t('viewPassword')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{backgroundColor: "transparent"}} onClick={() => {
                                    showOrHidePassword();
                                }}>
                                    {visibleIconPassword}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={11} textAlign={'right'}>
                    <a
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 25,
                            fontSize: '.85em',
                            color: 'var(--main-winahost-color)',
                            textDecoration: 'none',
                        }}
                        href={RECOVERY_PASSWORD_OWNERS}
                    >
                        {t('forgetPassword')}
                    </a>
                </Grid>

                {displayError && (
                    <Grid item xs={11} mt={1} textAlign={'center'}>
                        <Alert severity="error">{t(displayErrorMessage)}</Alert>
                    </Grid>
                )}

                {displayLoader && (
                    <Grid item xs={11} mt={1} textAlign={'center'}>
                        <CircularProgress/>
                    </Grid>
                )}

                <Grid item xs={11} mt={1} textAlign={'center'}>
                    <Button variant="contained"
                            style={{
                                width: '100%',
                                backgroundColor: 'var(--main-winahost-color)',
                                borderRadius: 25,
                                color: 'white',
                                fontWeight: 'bold'
                            }}
                            onClick={() => {
                                loginForm();
                            }}
                    >
                        {t('enter')}
                    </Button>
                </Grid>
            </Grid>
        </AuthLayout>
    );
}

export default LoginPage;
