import {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {Blog as BlogInterface} from "../../interfaces/BlogInterface";
import {getSingleBlogData} from "../resourcesSection/hooks/getSingleBlog";
import {Button, Grid, Hidden, Typography} from "@mui/material";
import * as React from "react";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import BaseLayout from "../../Shared/layouts/baseLayout";
import NotFound from "../notFoundSection/notFound";
import SimpleSkeleton from "../../components/skeletons/simpleSkeleton";
import MinimalistBlogSection from "../resourcesSection/sections/minimalistBlogSection";
import {getBlogsData} from "../resourcesSection/hooks/getBlogs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useLanguageContext} from "../../context/languageProvider";

const Blog = () => {

    const lang = useLanguageContext();

    const navigate = useNavigate();
    let {blogPath} = useParams();

    const [blog, setBlog] = useState<BlogInterface | null>(null);
    const [prevBlog, setPrevBlog] = useState<any | null>(null);
    const [nextBlog, setNextBlog] = useState<any | null>(null);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (blogPath) {
            getSingleBlogData(blogPath).then(res => {
                if (res.length === 0) {
                    setNotFound(true);
                }
                setBlog(res)
            });
        }

        getBlogsData('es').then(res => {
            const currentBlogIndex = res.findIndex((b: any) => b.path === '/' + blogPath);
            if (currentBlogIndex + 1 <= res.length) {
                setNextBlog(res[currentBlogIndex + 1])
            }
            if (currentBlogIndex - 1 >= 0) {
                setPrevBlog(res[currentBlogIndex - 1])
            }
        });

    }, []);

    const goBackNavigation = () => {
        navigate(-1);
    }

    if (!blog) return <SimpleSkeleton/>;
    if (notFound) return <NotFound/>

    return (
        <BaseLayout>
            <Grid container justifyContent={'center'} mt={5} mb={9}>
                <Grid item xs={10} sm={11} md={10} lg={8} xl={8}>
                    <Grid item xs={12} mb={2}>
                        <Button variant="text"
                                style={{color: 'gray'}}
                                startIcon={<ArrowBackIcon/>}
                                onClick={() => goBackNavigation()}
                        >
                            {'Atrás'}
                        </Button>
                    </Grid>
                    <Grid container justifyContent={'center'} spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant={'body1'} fontSize={'1.7em'} color={'black'}
                                        fontWeight={600} mb={5} textAlign={'center'}
                                        dangerouslySetInnerHTML={createMarkup(blog.title)}/>
                            <img src={blog.picture}
                                 alt={blog.path}
                                 style={{width: '100%', borderRadius: 30, marginBottom: 40}}
                            />
                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(blog.text)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} mt={9} mb={9}>
                <Grid item xs={11} sm={11} md={10} lg={8} xl={8}>
                    <Grid container justifyContent={'center'} spacing={4}>
                        <Grid item xs={12} md={5} lg={4}>
                            {prevBlog &&
                            <>
                                <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                             mb={3} ml={3}
                                            dangerouslySetInnerHTML={createMarkup('Artículo anterior')}/>
                                <MinimalistBlogSection {...prevBlog}/>
                            </>
                            }
                        </Grid>
                        <Hidden mdDown>
                            <Grid item md={2} lg={4}/>
                        </Hidden>
                        <Grid item xs={12} md={5} lg={4}>
                            {nextBlog &&
                            <>
                                <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                             mb={3} mr={3} textAlign={'right'}
                                            dangerouslySetInnerHTML={createMarkup('Artículo siguiente')}/>
                                <MinimalistBlogSection {...nextBlog}/>
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>
    );
}

export default Blog;
