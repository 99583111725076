export const LANG = [
    {
        'shortLang': 'es',
        'langName': 'Spanish'
    },
    {
        'shortLang': 'en',
        'langName': 'English'
    }
];

export const LANG_FLAGS = {
    'es': 'https://winahost.s3.eu-west-1.amazonaws.com/images/Flag_of_Spain.svg',
    'en': 'https://winahost.s3.eu-west-1.amazonaws.com/images/Flag_of_the_United_Kingdom.svg'
}

