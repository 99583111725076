import {Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import BoxAutoAdjust from "../../../Shared/utils/boxAutoAdjust";
import {useEffect, useState} from "react";
import {EN_SERVICE, ENGLISH_MAIN_ROUTE, ES_SERVICE} from "../../../routes/constantsRoutes";
import {EARN_ICON, GOOGLESHEETS_ICON, HOUSE_ICON, TODO_ICON} from "../../../Shared/Constants/iconsConstants";
import {useLanguageContext} from "../../../context/languageProvider";


const WinahostAdvantages = () => {

    const lang = useLanguageContext();
    const {t} = useTranslation('', {keyPrefix: 'mainPage.winahostAdvantages'});

    const servicePath = lang.language === 'es' ? ES_SERVICE : ENGLISH_MAIN_ROUTE + EN_SERVICE;

    const advantages = [
        {
            description: t('advantagesSquareProcess'),
            icon: HOUSE_ICON
        },
        {
            description: t('advantagesSquareAvailability'),
            icon: TODO_ICON
        },
        {
            description: t('advantagesSquareTransparency'),
            icon: GOOGLESHEETS_ICON
        },
        {
            description: t('advantagesSquarePerformance'),
            icon: EARN_ICON
        }
    ];



    const [boxHeight, setBoxHeight] = useState(0);

    const reAdjustBoxHeight = () => {
        const boxDataHeight = BoxAutoAdjust('itemAdvantagesContent');
        setBoxHeight(boxDataHeight);
    }

    useEffect(() => {
        setTimeout(() => {
            reAdjustBoxHeight();
        }, 100);
    }, []);

    return (
        <>
            <Grid container justifyContent={'center'} mt={5}>
                <Grid item xs={12} md={6}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={10} mt={5} textAlign={'center'}>
                            <Typography variant={'h2'}
                                        fontSize={'1.7em'}
                                        mb={5}
                                        color={'black'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('advantagesTitle'))}/>

                            <Typography variant={'body1'}
                                        fontSize={'1em'}
                                        mb={5}
                                        color={'black'}

                                        dangerouslySetInnerHTML={createMarkup(t('advantagesBody'))}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} mb={5} className={'mainAdvantagesContent'}>
                <Grid item xs={10} sm={10} md={6} lg={6} xl={4} mt={4} mb={4}>
                    <Grid container justifyContent={'center'} spacing={2}>
                        {advantages.map((advantage, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6}
                                      className={'itemAdvantagesContent'}>
                                    <Paper elevation={3} style={{borderRadius: 20, height: (boxHeight) ? boxHeight : 'auto'}}>
                                        <Grid container justifyContent={'center'}>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent={'center'}>
                                                    <Grid item xs={3} style={{
                                                        backgroundColor: 'var(--main-winahost-color)',
                                                        padding: 15,
                                                        marginTop: 20,
                                                        borderRadius: 15
                                                    }}>
                                                        <img src={advantage.icon}
                                                             alt="icon"
                                                             width={'100%'}
                                                             height={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} textAlign={'center'}>
                                                <Typography variant={'body1'} fontSize={'1em'}
                                                            p={4}
                                                            color={'black'}
                                                            fontWeight={400}
                                                            dangerouslySetInnerHTML={createMarkup(advantage.description)}/>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign={'center'} mt={3} mb={5}>
                    <Link rel={'canonical'} key={'main_page'} href={`${servicePath}`}
                          underline="none"
                          className={'custom-winahost-button'}>
                                <span style={{fontWeight: 'bold'}}>
                                    {t('advantagesButton')}
                                </span>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}

export default WinahostAdvantages;
