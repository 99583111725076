import React, {useEffect} from 'react';
import {Grid} from "@mui/material";

import './../../Styles/globals.css'
import './../../Styles/drawerCss.css';
import './../../Styles/footerCss.css';

import NavBar from "../../components/navbar/navBar";
import Footer from "../../components/footer/footer";
import WhatsappLogo from "../sharedTexts/WhatsappLogo";
import {checkReferencePathId} from "../utils/redirectToId";


interface props {
    children: JSX.Element | JSX.Element[]
}

const BaseLayout = ({children}: props) => {

    useEffect(() => {
        setTimeout(() => {
            checkReferencePathId(window.location.href);
        }, 1500);
    }, []);

    return (
        <Grid container>
            <NavBar/>
            <Grid container mt={'65.5px'} justifyContent="center">
                <Grid item xs={12} className={'MobileScreenLayout'}>
                    {children}
                    <WhatsappLogo/>
                </Grid>
            </Grid>
            <Footer/>
        </Grid>
    );
}

export default BaseLayout;
