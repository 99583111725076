import BaseLayout from "../../Shared/layouts/baseLayout";
import LegalESTextContent from "../../Shared/sharedTexts/legalESTextContent";
import {useLanguageContext} from "../../context/languageProvider";
import LegalENTextContent from "../../Shared/sharedTexts/legalENTextContent";


const LegalText = () => {

    const lang = useLanguageContext();

    const legalTextContent = lang.language === 'es' ? <LegalESTextContent/> : <LegalENTextContent/>;

    return (
        <BaseLayout>
            {legalTextContent}
        </BaseLayout>
    );
}

export default LegalText;
