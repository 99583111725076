

export const getMonthByDate = (date: Date, lang: string) => {
    const month: string = lang === 'es' ? _returnEsMonthWord(date.getMonth()) : _returnEnMonthWord(date.getMonth());
    return month + ' ' + date.getDate() + ', ' + date.getFullYear();
}

export const getMonthStringFromNumber = (number: number): string => _returnEsMonthWord(number);

const _returnEsMonthWord = (month: number) => {
    switch (month) {
        case 0: return 'Enero';
        case 1: return 'Febrero';
        case 2: return 'Marzo';
        case 3: return 'Abril';
        case 4: return 'Mayo';
        case 5: return 'Junio';
        case 6: return 'Julio';
        case 7: return 'Agosto';
        case 8: return 'Septiembre';
        case 9: return 'Octubre';
        case 10: return 'Noviembre';
        case 11: return 'Diciembre';
        default: return 'not-found';
    }
}

const _returnEnMonthWord = (month: number) => {
    switch (month) {
        case 0: return 'January';
        case 1: return 'February';
        case 2: return 'March';
        case 3: return 'April';
        case 4: return 'May';
        case 5: return 'June';
        case 6: return 'July';
        case 7: return 'August';
        case 8: return 'September';
        case 9: return 'October';
        case 10: return 'November';
        case 11: return 'December';
        default: return 'not-found';
    }
}

