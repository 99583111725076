import {createContext, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface props {
    children: JSX.Element
}

interface LanguageContextType {
    language: string,
    changeLanguage: (lang: string) => void
}

// @ts-ignore
const LanguageContext = createContext<LanguageContextType>();

export const useLanguageContext = () => {
    return useContext(LanguageContext);
}

export const LanguageProvider = ({children}: props) => {

    const {i18n} = useTranslation();
    const [language, setLanguage] = useState<string>(i18n.language);

    useEffect(() => {
        const pathLanguage = window.location.pathname.split('/')[1];
        changeLanguage(pathLanguage === 'en' ? pathLanguage : 'es');
    }, [])

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        setLanguage(lang)
    }

    return (
        <LanguageContext.Provider value={{language, changeLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
}
