import {Grid, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {ChangeEvent} from "react";
import {LeadInterface} from "../../interfaces/leadInterface";
import * as React from "react";
import {useTranslation} from "react-i18next";

interface props {
    lead: LeadInterface,
    setLead: Function,
    customFunction: Function,
}

const PropertyQuestionsForm = (
    {
        lead,
        setLead,
        customFunction
    }: props) => {

    const {t} = useTranslation('', {keyPrefix: 'formularies.price.rentalStepper'})

    const callCustomFunction = () => {
        customFunction();

    }

    const changeLeadData = (key: string, value: any) => {

        if ((key === 'bedrooms' || key === 'bathrooms' || key === 'accommodates') && value < 0) {
            value = 0;
        }
        setLead({
            ...lead,
            property: {
                ...lead.property,
                [key]: value
            }
        });
    }

    return (
        <>
            <Grid container p={2} spacing={3}>

                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('questions.firstQuestionTitle')}</FormLabel>
                        <RadioGroup aria-label="owner" name="owner" value={lead.property.owner}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('owner', e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio/>} label={t('answers.yes')}/>
                            <FormControlLabel value="no" control={<Radio/>} label={t('answers.no')}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('questions.secondQuestionTitle')}</FormLabel>
                        <RadioGroup aria-label="gender" name="availability" value={lead.property.availability} onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('availability', e.target.value)}>
                            <FormControlLabel value="fulltime" control={<Radio/>}
                                              label={t('answers.fullTime')}/>
                            <FormControlLabel value="halftime" control={<Radio/>}
                                              label={t('answers.parcialTime')}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>


                <Grid item xs={12} md={6} mt={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('questions.thirdQuestionTitle')}</FormLabel>
                        <RadioGroup aria-label="hire" name="hire" value={lead.property.hire}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('hire', e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio/>} label={t('answers.yes')}/>
                            <FormControlLabel value="no" control={<Radio/>} label={t('answers.no')}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} mt={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('questions.fourthQuestionTitle')}</FormLabel>
                        <RadioGroup aria-label="licence" name="licence" value={lead.property.licence}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('licence', e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio/>} label={t('answers.yes')}/>
                            <FormControlLabel value="no" control={<Radio/>} label={t('answers.noIllDo')}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Button
                        disabled={lead.property.address.length <= 3}
                        variant={'outlined'}
                        style={{
                            width: '100%',
                            padding: '20px 0',
                            backgroundColor: 'var(--main-winahost-color)',
                            color: 'white',
                            borderRadius: 50
                        }}
                        onClick={() => callCustomFunction()}
                    >
                        {t('rentalStepperButton')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default PropertyQuestionsForm
