import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import ScreenSizeResource from "../../../Shared/utils/screenSizeResource";
import {useTranslation} from "react-i18next";
import {BOMBILLA} from "../../../Shared/Constants/imageConstants";

const OurVisionAndMission = () => {

    const {t} = useTranslation('', {keyPrefix: 'usPage.ourVision'});

    const {width} = ScreenSizeResource();

    const reverse = (width < 900) ? 'column-reverse' : 'row';

    return (
        <Grid container justifyContent={'center'} mt={9} mb={5}>
            <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                <Grid container justifyContent={'center'} spacing={3} direction={reverse}>
                    <Grid item xs={12} sm={11} md={6} lg={6} style={{margin: 'auto', textAlign: 'center'}}>
                        <img src={BOMBILLA} alt={"bedroom_house"} width={'90%'} style={{borderRadius: 30}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={'h2'} fontSize={'1.7em'} mb={2} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('title'))}/>
                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('firstParagraph'))}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('secondParagraph'))}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('thirdParagraph'))}/>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OurVisionAndMission;
