import {Box, Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {
    EN_CONTACT,
    EN_TECHNOLOGY,
    ENGLISH_MAIN_ROUTE,
    ES_CONTACT,
    ES_TECHNOLOGY
} from "../../../routes/constantsRoutes";
import {CONNECTING_WORLD} from "../../../Shared/Constants/imageConstants";
import {PC_CONFIGURATION, TECHNOLOGY_EARNING} from "../../../Shared/Constants/logosConstants";
import {useLanguageContext} from "../../../context/languageProvider";

interface squareInterface {
    image: string,
    alt: string,
    title: string,
    description: string
}

const Property360Space = () => {

    const lang = useLanguageContext();
    const {t} = useTranslation('', {keyPrefix: 'servicePage.property360Space'});

    const technologyPath = lang.language === 'es' ? ES_TECHNOLOGY : ENGLISH_MAIN_ROUTE + EN_TECHNOLOGY;

    const squares: squareInterface[] = [
        {
            image: PC_CONFIGURATION,
            alt: 'pc_configuration',
            title: t('squares.bigData.title'),
            description:  t('squares.bigData.description')
        },
        {
            image: TECHNOLOGY_EARNING,
            alt: 'technology_earning',
            title: t('squares.application.title'),
            description: t('squares.application.description')
        }
    ];

    return (
        <Grid container justifyContent={'center'} mb={5} className={'section360'}>
            <Grid item xs={12} sm={10} md={9} lg={8} xl={7}>
                <Grid container justifyContent={'center'} spacing={5} direction="row"
                      alignItems="center">
                    <Grid item xs={11} sm={8} md={8}>
                        <Typography variant="h3" component="h3" fontSize={'1.7em'} mb={4} fontWeight={700}
                                    color={'black'} textAlign={'center'}
                                    dangerouslySetInnerHTML={createMarkup(t('property360SpaceTitle'))}/>

                        <Grid container justifyContent={'center'} spacing={3}
                              direction="row"
                              alignItems="center">
                            {squares.map((square: squareInterface, index: number) => {
                                return (
                                    <Grid key={index} item xs={12}>
                                        <Paper style={{borderRadius: 30}} elevation={5}>
                                            <Grid container
                                                  direction="row"
                                                  justifyContent="center"
                                                  alignItems="center"
                                            >
                                                <Grid item sm={12} md={2}>
                                                    <Box m={1} style={{
                                                        backgroundColor: 'var(--main-winahost-color)',
                                                        margin: '30px auto',
                                                        width: '70px',
                                                        height: '70px',
                                                        borderRadius: 50
                                                    }}>
                                                        <img src={square.image}
                                                             alt={square.alt}
                                                             style={{
                                                                 position: 'relative',
                                                                 top: '17px',
                                                                 left: '17px',
                                                                 width: '40px',
                                                                 height: '40px'
                                                             }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={12} md={3}>
                                                    <Typography variant="body1" fontSize={'1.7em'} mb={5}
                                                                fontWeight={600} style={{textAlign: 'center', margin: 'auto 0', padding: '0 40px 0 0', color: 'var(--main-winahost-color)'}}
                                                                dangerouslySetInnerHTML={createMarkup(square.title)}/>
                                                </Grid>
                                                <Grid item sm={12} md={7}>
                                                    <Typography variant="body1" mb={5}
                                                                style={{textAlign: 'left', margin: 'auto 0', padding: '0 40px 20px 20px'}}
                                                                dangerouslySetInnerHTML={createMarkup(square.description)}/>
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>
                                );
                            })}
                        </Grid>

                    </Grid>
                    <Grid item xs={10} sm={4} md={4}>
                        <img src={CONNECTING_WORLD}
                             alt={"connecting_world"}
                             style={{width: '100%'}}
                        />
                    </Grid>
                    <Grid container mt={5}>
                        <Grid item xs={11} textAlign={'end'}>
                            <Link rel={'canonical'} href={technologyPath}
                                  underline="none"
                                  className={'custom-winahost-button'}>
                                <span style={{fontWeight: 'bold'}}>
                                    {t('property360SpaceButton')}
                                </span>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Property360Space;
