import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import PlanPricePageSection from "./planPricePageSection";
import {WORKER_GUY} from "../../../Shared/Constants/imageConstants";
import {useTranslation} from "react-i18next";

const ProfessionalSection = () => {

    const {t} = useTranslation('', {keyPrefix: 'pricesPage.pages.professional'})

    const textProperties = [
        'content.realEstateInvestmentConsulting',
        'content.touristRentalOutsourcing',
        'content.buildingPropertyManagement',
        'content.realEstateDevelopmentsManagement',
        'content.assetManagementCompanies',
        'content.realEstateManagement',
        'content.profitabilityAnalysis',
    ];

    return (
        <PlanPricePageSection>
            <Grid container justifyContent={'center'} mt={5} mb={5}>
                <Grid item xs={11} sm={10} md={8} lg={8}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={11} md={8} lg={6}>

                            <Typography variant={'body1'} fontSize={'1.7em'} fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('title'))}/>

                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup(t('subTitle'))}/>

                            <Grid item xs={12} mt={5} p={1}>
                                <ul>
                                    {textProperties.map((textProperty, index) => {
                                        return (
                                            <li key={index}>
                                                <Typography variant={'body1'} mt={2}
                                                            dangerouslySetInnerHTML={createMarkup(t(textProperty))}/>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Grid>

                        </Grid>
                        <Grid item xs={11} sm={11} md={4} lg={6}>
                            <img src={WORKER_GUY} alt={"worker_guy"} width={'100%'}
                                 style={{borderRadius: 30}}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} mt={8}>
                        <Grid item xs={11} sm={11} md={10} lg={8}>

                            <Typography variant={'body1'} fontSize={'1.6em'} fontWeight={600} mb={5}
                                        dangerouslySetInnerHTML={createMarkup(t('bodyTitle'))}/>

                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup(t('body'))}/>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </PlanPricePageSection>
    );
}

export default ProfessionalSection;
