import React, {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import LeadBasicFormData from "../../contactNow/forms/leadBasicFormData";
import LegalContent from '../../../../Shared/sharedTexts/legalESTextContent';
import {validationEmail, validationPhone} from "../../../../Shared/utils/validations";

type Props = { lead: LeadInterface; setLead: Function; currentFunction: Function; }

const HeaderContactPropertiesFormLanding = ({lead, setLead, currentFunction}: Props) => {
    const [sendButton, setSendButton] = useState(true);

    const [{political, dialog}, setState] = useState({political: false, dialog: false});
    const updateState = (newState: Partial<{ political: boolean; dialog: boolean }>) =>
        setState(prev => ({...prev, ...newState}));

    const availableSendButton = () => {
        let dataEmail = validationEmail(lead.user.email);
        let dataPhone = validationPhone(lead.user.phone);

        setSendButton((dataEmail || dataPhone || !political));
    }

    useEffect(() => {
        availableSendButton();
    }, [lead, political])

    return (
        <Grid item xs={11} className="mainPageTitle">
            {[
                {
                    variant: "h1" as "h1",
                    fontSize: "1.8em",
                    content: "Consigue el máximo rendimiento de tu propiedad, nosotros nos encargamos del resto"
                },
                {
                    variant: "h2" as "h2",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    mt: 5,
                    content: "Rellena los datos, ¡Hoy mismo te contactaremos!"
                }
            ].map(({content, ...props}, i) => (
                <Typography key={i} {...props} dangerouslySetInnerHTML={createMarkup(content)}/>
            ))}

            <Grid item xs={12} mt={3} mb={5}>
                <LeadBasicFormData lead={lead} setLead={setLead}/>
            </Grid>

            <Button
                fullWidth
                className={'custom-winahost-button'}
                disabled={sendButton}
                onClick={() => currentFunction()}
            >
                <strong>contacta ahora</strong>
            </Button>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={political}
                        onChange={e => updateState({political: e.target.checked})}
                    />
                }
                label={
                    <>
                        Acepto las{" "}
                        <Button
                            onClick={() => updateState({dialog: true})}
                            sx={{
                                p: 0,
                                minWidth: 'unset',
                                textTransform: 'none',
                                textDecoration: 'underline',
                                color: '#1976d2',
                                '&:hover': {bgcolor: 'transparent'}
                            }}
                        >
                            políticas de uso y privacidad
                        </Button>
                    </>
                }
            />

            <Dialog
                open={dialog}
                onClose={() => updateState({dialog: false})}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <LegalContent/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateState({dialog: false})}>
                        Cerrar
                    </Button>
                    <Button
                        onClick={() => updateState({political: true, dialog: false})}
                        variant="contained"
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default HeaderContactPropertiesFormLanding;