import {Grid, Hidden, Link, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {EN_CONTACT, ENGLISH_MAIN_ROUTE, ES_CONTACT} from "../../../routes/constantsRoutes";
import {
    SALA_ESTAR_LUJO_LOFT_RENDERIZADO_3D_ESTANTERIA_CERCA_ESTANTERIA,
    TRABAJADORES_HABLAR_TELEFONO_HACER_PAPELEO
} from "../../../Shared/Constants/imageConstants";
import {useLanguageContext} from "../../../context/languageProvider";

const WhatIsWinahost = () => {

    const lang = useLanguageContext();
    const {t} = useTranslation('', {keyPrefix: 'mainPage.whatIsWinahost'});

    return (
        <Grid container justifyContent={'center'} mt={10}>
            <Grid item xs={12} sm={8} md={8} lg={10} xl={10}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10} sm={12} md={12} lg={7}>
                        <Typography variant="body1" fontSize={'1.5rem'} mb={3} fontWeight={600}
                                    color={'black'} textAlign={'center'} justifySelf={'center'}
                                    dangerouslySetInnerHTML={createMarkup(t('whatIsWinahostTitle'))}/>

                        <Typography variant="body1" mb={6} fontWeight={200}
                                    color={'black'}
                                    dangerouslySetInnerHTML={createMarkup(t('whatIsWinahostBody'))}/>

                        <Hidden lgDown>
                            <Link rel={'canonical'} key={'main_page'} href={lang.language === 'es' ? ES_CONTACT : ENGLISH_MAIN_ROUTE + EN_CONTACT}
                                  underline="none"
                                  className={'custom-winahost-button'}
                            >
                                <span>
                                    {t('whatIsWinahostButton')}
                                </span>
                            </Link>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5} mt={5}>
                        <Grid container justifyContent={'center'} spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'center'}>
                                <img src={SALA_ESTAR_LUJO_LOFT_RENDERIZADO_3D_ESTANTERIA_CERCA_ESTANTERIA}
                                     alt={"pool_house"}
                                     style={{width: '60%', borderRadius: 30, margin: '0 auto'}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'center'}>
                                        <img src={TRABAJADORES_HABLAR_TELEFONO_HACER_PAPELEO}
                                             alt={"livingroom_house"}
                                             style={{width: '60%', borderRadius: 30, margin: '0 auto'}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5} textAlign={'center'} mt={5}>
                        <Hidden lgUp>
                            <Link rel={'canonical'} key={'main_page'} href={lang.language === 'es' ? ES_CONTACT : ENGLISH_MAIN_ROUTE + EN_CONTACT}
                                  underline="none"
                                  className={'custom-winahost-button'}
                            >
                                <span>
                                    {t('whatIsWinahostButton')}
                                </span>
                            </Link>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default WhatIsWinahost;
