import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import '../../../Styles/historiesCssBox.css';
import { ES_CONTACT } from "../../../routes/constantsRoutes";
import "@fontsource/poppins";

const PropietarioSolution1 = () => {
    const { t } = useTranslation('', { keyPrefix: 'mainPage.propietario' });
    const servicePath = ES_CONTACT;

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            mt={3}
            sx={{
                fontFamily: "'Poppins', sans-serif",
                textAlign: "left",
                px: 2,
                background: "linear-gradient(to bottom, #367E7F, #FFFFFF)",
                color: "black",
                borderRadius: '10px',
                marginTop: "-30px",
                paddingTop: "120px",
                paddingBottom: "24px",
            }}
        >
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <Typography
                        variant="h2"
                        fontSize={{ xs: '20px', sm: '25px' }}
                        mb={4}
                        fontWeight={600}
                    >
                        ¿Tienes un bloque de apartamentos o un hotel boutique y buscas obtener el máximo rendimiento de manera segura?
                    </Typography>

                    <Typography
                        variant="body1"
                        fontSize={{ xs: '14px', sm: '16px' }}
                        mb={5}
                        sx={{ lineHeight: 1.6 }}
                    >
                        Cuanto más grande sea el edificio, más compleja se vuelve la gestión, y sin la tecnología adecuada, es difícil maximizar el rendimiento.
                        <br />
                        <br />
                        En Winahost, usamos las herramientas tecnológicas basadas en <strong>DATA</strong> y <strong>Business Intelligence</strong> más avanzadas del mercado para gestionar tu estructura de manera eficiente y obteniendo la <strong>máxima rentabilidad</strong> con el <strong>alquiler vacacional o temporal</strong>.
                        <br />
                        <br />
                        <span style={{ textDecoration: 'underline' }}>
                            Nos aseguramos de que cada unidad de tu activo alcance su máximo potencial.
                        </span>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PropietarioSolution1;